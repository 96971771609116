import React, { useState } from "react";

const MaintenanceMode = ({ onAccessGranted }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleAccess = () => {
    if (password === process.env.REACT_APP_MAINTENANCE_PASSWORD) {
      localStorage.setItem("maintenance_access", "granted");
      onAccessGranted();
    } else {
      setError("Incorrect password. Please try again.");
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20%" }}>
      <h1>Site is under maintenance</h1>
      <p>Please enter the password to access:</p>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleAccess}>Access</button>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default MaintenanceMode;
