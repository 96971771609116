import { useEffect, useState } from 'react';
import MaintenanceMode from './components/MaintenanceMode';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

import './i18n';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';
import BlogPost from './blog/BlogPost';
import Blog from './pages/Blog';
import Portfolio from './pages/Portfolio';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import Checkout from './pages/Checkout';
import LeadModal from './components/LeadModal';


// Declare gtag function
function gtag() {
  window.dataLayer.push(arguments);
}

export default function App() {

  const { i18n } = useTranslation();

  // Get language from the URL path
  const { lng } = useParams();

  // Change the language whenever the path changes
  useEffect(() => {
    if (lng && i18n.language !== lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  // Initialize GA4
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-BJ3TTZZ74F');
  }, []);

  // Function to send custom events to GA4
  const sendToAnalytics = ({ id, name, value }) => {
    gtag('event', name, {
      event_category: 'Web Vitals',
      event_label: id,
      value: Math.round(name === 'CLS' ? value * 1000 : value), // Convert to milliseconds
      non_interaction: true, // Make sure it doesn't affect bounce rate
    });
  };

  // Report Core Web Vitals to GA4
  useEffect(() => {
    getCLS(sendToAnalytics);
    getFID(sendToAnalytics);
    getLCP(sendToAnalytics);
    getFCP(sendToAnalytics);
    getTTFB(sendToAnalytics);
  }, []); // Ensure this effect runs only once on mount


  const [isMaintenanceMode, setIsMaintenanceMode] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const allowedDomain = process.env.REACT_APP_ALLOWED_DOMAIN;
    const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;
  
    console.log("Current Domain:", currentDomain);
    console.log("Allowed Domain:", allowedDomain);
    console.log("Maintenance Mode:", maintenanceMode);
  
    if (maintenanceMode === "true" && currentDomain !== allowedDomain) {
      setIsMaintenanceMode(true);
  
      if (localStorage.getItem("maintenance_access") === "granted") {
        setHasAccess(true);
      }
    } else {
      setIsMaintenanceMode(false);
    }
  }, []);  

  const handleAccessGranted = () => {
    setHasAccess(true);
  };

  if (isMaintenanceMode && !hasAccess) {
    return <MaintenanceMode onAccessGranted={handleAccessGranted} />;
  }

  return (
    <HelmetProvider>
      <Router>
        <>
          <Helmet>
            <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="bacae79e-de49-461b-ba9b-06e936d1c810" data-blockingmode="auto" type="text/javascript"></script>
            <script async data-cookieconsent="marketing" src="https://www.googletagmanager.com/gtag/js?id=G-BJ3TTZZ74F"></script>
          </Helmet>
          <div id='main-wrapper' className='container'>
            <Header />
            <main>
              <LeadModal />
              <Routes>
                {/* Redirect default route to /en if no language is specified */}
                <Route path="/" element={<Home />} />

                {/* Default language (SK) routes without language in URL */}
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/ochrana-osobnych-udajov" element={<PrivacyPolicy />} />
                <Route path="/kontakt" element={<Contact />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="*" element={<PageNotFound />} status={404} />
                {/* <Route path="/checkout" element={<Checkout />} /> */}

                {/* Language-specific routes for non-SK languages */}
                <Route path='/:lng'>
                  <Route index element={<Home />} />
                  <Route path="portfolio" element={<Portfolio />} />
                  <Route path="ochrana-osobnych-udajov" element={<PrivacyPolicy />} />
                  <Route path="kontakt" element={<Contact />} />
                  <Route path="blog" element={<Blog />} />
                  <Route path="blog/:slug" element={<BlogPost />} />
                  <Route path="*" element={<PageNotFound />} status={404} />
                </Route>
              </Routes>
            </main>
          </div>
          <Footer />
        </>
      </Router>
    </HelmetProvider>
  )
}