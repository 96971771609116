import React, { useEffect } from 'react';
import LeadContactForm from './LeadContactForm';
import { useTranslation, Trans } from 'react-i18next';

const LeadModal = () => {
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => setShow(false);

  return (
    <>
      <div className={`modal lead-modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} id="leadModal" tabIndex="-1" role="dialog" aria-labelledby="leadModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="leadModalLabel">{t('Získajte Bezplatnú Konzultáciu')}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                <Trans i18nKey="nechajte-mi-svoj-mail">
                  Nechajte mi svoj e-mail a <b>získajte bezplatnú konzultáciu!</b> Rád vám pomôžem s vašou webovou stránkou.
                </Trans>
              </p>
              <LeadContactForm />
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show" />}
    </>
  );
};

export default LeadModal;
